<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">工单管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">工单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="工单编号" class="searchboxItem ci-full">
              <span class="itemLabel">工单编号:</span>
              <el-input
                v-model="orderNo"
                clearable
                placeholder="请输入工单编号"
                size="small"
              ></el-input>
            </div>
            <div title="提出人" class="searchboxItem ci-full">
              <span class="itemLabel">提出人:</span>
              <el-input
                v-model="orderPersonName"
                clearable
                placeholder="请输入提出人"
                size="small"
              ></el-input>
            </div>
            <div title="创建人" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">创建人:</span>
              <el-input
                v-model="createUser"
                clearable
                placeholder="请输入创建人"
                size="small"
              ></el-input>
            </div>
            <div title="工单内容" class="searchboxItem ci-full">
              <span class="itemLabel">工单内容:</span>
              <el-input
                v-model="chatContent"
                clearable
                placeholder="请输入工单内容"
                size="small"
              ></el-input>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="问题分类" class="searchboxItem ci-full">
              <span class="itemLabel">问题分类:</span>
              <el-select
                v-model="orderquestionType"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in workorderquestionType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="单位类型" class="searchboxItem ci-full">
              <span class="itemLabel">单位类型:</span>
              <el-select
                v-model="ordercompanyType"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in workordercompanyType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="工单状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">工单状态:</span>
              <el-select
                v-model="orderState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in workorderState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-input
                v-model="orderCompanyName"
                clearable
                placeholder="请输入单位名称"
                size="small"
              ></el-input>
            </div>
          </div>
          <div class="searchbox">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="orderAreaId"
                :options="areatreeList"
                :props="propsarea"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
            <div title="工单类型" class="searchboxItem ci-full">
              <span class="itemLabel">工单类型:</span>
              <el-select
                v-model="orderType"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in workorderTypelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="提交时间" class="searchboxItem ci-full">
              <span class="itemLabel">提交时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df" style="padding-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="addModel()"
                >新建工单</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="removeSearch()"
                >清空搜索条件</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="工单编号"
                align="left"
                show-overflow-tooltip
                prop="orderNo"
                min-width="150px"
                fixed
              >
                <template slot-scope="scope">
                  <div style="display:flex;align-items: center;">
                    <span
                      style="width:5px;height:5px;background:red;border-radius:50%"
                      v-if="scope.row.orderToView"
                    ></span>
                    {{ scope.row.orderNo }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="工单内容"
                align="left"
                show-overflow-tooltip
                prop="chatContent"
                min-width="240px"
              />
              <el-table-column
                label="提出单位名称"
                align="left"
                show-overflow-tooltip
                prop="orderCompanyName"
                width="260px"
              />
              <el-table-column
                label="单位类型"
                align="left"
                show-overflow-tooltip
                prop="orderCompanyType"
                min-width="80px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "WORK_ORDER_COMPANY_TYPE",
                      scope.row.orderCompanyType || "--"
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="提出人"
                align="left"
                show-overflow-tooltip
                prop="orderPersonName"
                min-width="120px"
              />
              <el-table-column
                label="提出人电话"
                align="left"
                show-overflow-tooltip
                prop="orderPersonPhone"
                min-width="150px"
              />
              <el-table-column
                label="提出时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="问题分类"
                align="left"
                show-overflow-tooltip
                prop="orderQuestionType"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "WORK_ORDER_QUESTION_TYPE",
                      scope.row.orderQuestionType || "--"
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="处理人"
                align="left"
                show-overflow-tooltip
                prop="updateName"
                min-width="120px"
              />
              <el-table-column
                label="处理时间"
                align="left"
                show-overflow-tooltip
                prop="updateTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.updateTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="orderAreaName"
                min-width="120px"
              />
              <el-table-column
                label="创建人"
                align="left"
                show-overflow-tooltip
                prop="createUser"
              />
              <el-table-column
                label="工单状态"
                align="left"
                show-overflow-tooltip
                prop="orderState"
                min-width="120px"
                fixed="right"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "WORK_ORDER_STATE",
                      scope.row.orderState || "--"
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="工单类型"
                align="left"
                show-overflow-tooltip
                prop="orderType"
                min-width="80px"
                fixed="right"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "WORK_ORDER_TYPE",
                      scope.row.orderType || "--"
                    )
                  }}
                </template>
              </el-table-column>
                 
              <el-table-column
                label="操作"
                align="center"
                width="180px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="
                      lookOrder(
                        scope.row.orderId,
                        scope.row.chatId,
                        scope.row.orderState
                      )
                    "
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    :disabled="scope.row.orderState != '10'"
                    @click="deleteOrder(scope.row.orderId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    :disabled="!(scope.row.orderState== '30' || scope.row.orderState == '10')"
                    @click="completeOrder(scope.row.orderId)"
                    >完成</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
      <el-dialog
        title="查看工单"
        :visible.sync="toVoidLoding"
        top="1%"
        width="60%"
        @close="toVoidClose"
        class="toVoidLoding"
      >
        <div style="background:#f1f1f1;padding:15px 0">
          <div
            style="display: flex;justify-content: space-around;font-weight:800"
          >
            <span>提出单位名称:{{ tovoidData.orderCompanyName }}</span>
            <span
              >问题分类:{{
                $setDictionary(
                  "WORK_ORDER_QUESTION_TYPE",
                  tovoidData.orderQuestionType
                )
              }}</span
            >
            <span
              >工单类型:{{
                $setDictionary("WORK_ORDER_TYPE", tovoidData.orderType)
              }}</span
            >
            <span
              >工单状态:{{
                $setDictionary("WORK_ORDER_STATE", tovoidData.orderState)
              }}</span
            >
            <span>工单编号:{{ tovoidData.orderNo }}</span>
          </div>
        </div>
        <div :style="lorderState== '40' ? 'height:600px': 'height:400px'">
          <div class="ovy-a" ref="doma">
          <div ref="doms" id="doms">
            <div
              v-for="(item, index) in tovoidData.edoWorkChatTmpBOList"
              :key="index"
              style="margin-top:15px"
              :style="
                userJson.fullname != item.fullName
                  ? 'display:flex;justify-content: flex-start;flex-direction: column;'
                  : 'display:flex;justify-content: flex-end;flex-direction: column;'
              "
            >
              <div
              v-if=" userJson.fullname == item.fullName"
                style="display:flex"
                :style="
                  userJson.fullname != item.fullName
                    ? 'justify-content: flex-start;'
                    : 'justify-content: flex-end;'
                "
              >
                <div
                  class="rejectBox"
                  style="padding-right:8.5rem"
                  v-if="
                    item.orderState == '12' ||item.orderState == '11' ||
                      item.orderState == '13' ||item.orderState == '20' || item.orderState == '30'||
                      item.orderState == '14'
                  "
                >
                  <img
                    :src="
                      item.orderState == '14' ||  item.orderState == '11'
                        ? require('@/assets/reject.png')
                        : item.orderState == '13' ||item.orderState == '20' || item.orderState == '30'
                        ? require('@/assets/chatPass.png')
                        : item.orderState == '12'
                        ? require('@/assets/leader.png')
                        : ''
                    "
                    alt=""
                    width="20px"
                    height="20px"
                  />
                  <span
                    :style="
                      item.orderState == '14'||item.orderState == '11'
                        ? 'color:#F36464'
                        : item.orderState == '13'||item.orderState == '20'||item.orderState == '30'
                        ? 'color:#5DD9B9'
                        : ''
                    "
                    >{{
                      item.orderState == "14"||item.orderState == '11'
                        ? "驳回处理"
                        : item.orderState == "13" || item.orderState == '20'
                        ? "同意处理"
                        : item.orderState == "12"
                        ? "待领导审核"
                        : item.orderState == '30' ? '研发处理完成':''
                    }}</span
                  >
                </div>
                <h3>
                  <span
                    style="font-size:.85rem;font-weight: 100;padding-left:.5rem;"
                    >{{ item.createTime | moment }}</span
                  >
                </h3>
              </div>
              <div
               v-else
                style="display:flex"
                :style="
                  userJson.fullname != item.fullName
                    ? 'justify-content: flex-start;'
                    : 'justify-content: flex-end;'
                "
              >
               <h3>
                  <span
                    style="font-size:.85rem;font-weight: 100;padding-left:.5rem;"
                    >{{ item.createTime | moment }}</span
                  >
                </h3>
                <div
                  class="rejectBox"
                  style="padding-left:8.5rem"
                  v-if="
                    item.orderState == '12' ||item.orderState == '11' ||
                      item.orderState == '13' || item.orderState == '20' ||item.orderState == '30' ||
                      item.orderState == '14'
                  "
                >
                  <img
                    :src="
                      item.orderState == '14'||item.orderState == '11'
                        ? require('@/assets/reject.png')
                        : item.orderState == '13'|| item.orderState == '20' ||item.orderState == '30'
                        ? require('@/assets/chatPass.png')
                        : item.orderState == '12'
                        ? require('@/assets/leader.png')
                        : ''
                    "
                    alt=""
                    width="20px"
                    height="20px"
                  />
                  <span
                    :style="
                      item.orderState == '14' ||item.orderState == '11'
                        ? 'color:#F36464'
                        : item.orderState == '13'|| item.orderState == '20' || item.orderState == '30'
                        ? 'color:#5DD9B9'
                        : ''
                    "
                    >{{
                      item.orderState == "14" ||item.orderState == '11'
                        ? "驳回处理"
                        : item.orderState == "13" ||item.orderState == '20'
                        ? "同意处理"
                        : item.orderState == "12"
                        ? "待领导审核"
                        :item.orderState == '30'?'研发处理完成':''
                    }}</span
                  >
                </div>
               
              </div>
              <div
                style="display:flex;margin-top:15px"
                :style="
                  userJson.fullname != item.fullName
                    ? 'justify-content: flex-start;'
                    : 'justify-content: flex-end;'
                "
              >
                <div
                  style="display:flex;flex-direction: column;align-items: center;width:65px"
                  v-if="userJson.fullname != item.fullName"
                >
                  <img
                    :src="
                      item.roleId == 1
                        ? require('@/assets/workyw.png')
                        : require('@/assets/workjg.png')
                    "
                    alt=""
                    width="30px"
                    height="30px"
                  />
                  <p style="font-size:.55rem">{{ item.fullName }}</p>
                </div>

                <div style="width:300px;">
                  <el-card shadow="always"  :class="
                      item.orderState == '14' ||  item.orderState == '11'
                        ? 'el_CardRed'
                        : item.orderState == '13' ||item.orderState == '20' || item.orderState == '30'
                        ? 'el_CardGreen'
                        : item.orderState == '12'
                        ? 'el_CardBlue'
                        : ''
                    ">
                    <div style="padding-bottom:10px">
                      {{ item.chatContent }}
                    </div>

                    <div v-show="item.tmpFileKeys.length > 0">
                      <el-collapse v-model="activeName" accordion>
                        <el-collapse-item
                          :class="
                            item.orderState == '14'
                              ? 'el_CardRed'
                              : item.orderState == '13'||item.orderState == '20' || item.orderState == '30'
                              ? 'el_CardGreen'
                              : item.orderState == '12'
                              ? 'el_CardBlue'
                              : ''
                          "
                          :title="'附件(' + item.tmpFileKeys.length + '个)'"
                          name="1"
                        >
                          <div
                            v-for="(item1, index) in item.tmpFileKeys"
                            :key="index"
                          >
                            {{ item1.name }}
                            <el-button
                              type="text"
                              style="padding:0"
                              :disabled="item1.fileType == 'TXT' || item1.fileType == 'txt'"
                              @click="jumpLook(index, item1)"
                              >预览</el-button
                            >
                            <el-button
                              type="text"
                              style="padding:0"
                              @click="jumpDown(index, item1.downloadUrl)"
                              >下载</el-button
                            >
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </div>
                  </el-card>
                </div>
                <div
                  style="display:flex;flex-direction: column;align-items: center;width:65px"
                  v-if="userJson.fullname == item.fullName"
                >
                  <img
                    :src="require('@/assets/workyw.png')"
                    alt=""
                    width="30px"
                    height="30px"
                  />
                  <p style="font-size:.55rem">{{ item.fullName }}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footers" v-if="lorderState!= '40'">
          <el-input
        
            v-model="chatContents"
            clearable
            placeholder="请输入"
            size="small"
            type="textarea"
          ></el-input>
          <div style="display:flex;align-items: center;">
            <el-upload
              class="upload-demo upload-btn"
              :action="actionUrl"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-change="uploadChange"
              multiple
              :limit="5"
              :file-list="fileList"
              :show-file-list="false"
              :auto-upload="false"
            >
              <el-button class="bgc-bv" size="mini">上传附件</el-button>
            </el-upload>
            <span
              style="color:#f46173;margin:15px 0 0 10px"
              >支持上传word、excel、文本、图片、pdf等，文件大小不超过3M，最多上传5个文件。</span
            >
          </div>
          <div style="display:flex;flex-direction: column;">
            <span
              style="color:#409EFF;text-align:left"
              v-for="(item, index) in NameList"
              :key="index"
            >
              {{ item.name }}
              <a @click="reomveExl(index)" style="color: red;margin:0 15px"
                >删除</a
              >
            </span>
          </div>
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            @click="toVoidDetermine"
            >发送</el-button
          >
          <!-- </div> -->
        </div>
      </el-dialog>
       <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
        @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div  class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="fileType == 'pdf' || fileType == 'PDF' "></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%">
        </div>
      </div>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder/workOrderEdit",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      orderNo: "", //工单编号
      orderPersonName: "",
      createUser: "",
      chatContent: "",
      orderState: "",
      orderquestionType: "",
      ordercompanyType: "",
      orderCompanyName: "",
      createTime: "",
      orderType:'',
      workorderquestionType: [],
      workordercompanyType: [],
      workorderState: [],
      workorderTypelist:[], //工单类型
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      toVoidLoding: false,
      tovoidData: {},
      chatContents: "",
      NameList: [],
      fileList:[],
      chatFileKeys: [],
       previewLoding1:false,
      ImgSrc:'',
      fileType:'',
    };
  },
  watch: {},
  created() {
    this.getdataList();
    this.getareatree();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          if (el.children) {
            el.children.map((els) => {
              els.disabled = false;
              if (els.children) {
                els.children.map((els1) => {
                  els1.disabled = false;
                });
              }
            });
          }
        });
        this.areatreeList = data;
      });
    },
    //类型
    getdataList() {
      const workorderquestionType = this.$setDictionary(
        "WORK_ORDER_QUESTION_TYPE",
        "list"
      );
      const workordercompanyType = this.$setDictionary(
        "WORK_ORDER_COMPANY_TYPE",
        "list"
      );
      const workorderstate = this.$setDictionary("WORK_ORDER_STATE", "list");
      const workordertype = this.$setDictionary("WORK_ORDER_TYPE", "list");
      const list = [];
      const workordercompanyTypelist = [];
      const workorderStatelist = [];
      const workorderTypelist = [];
      for (const key in workorderquestionType) {
        list.push({
          value: key,
          label: workorderquestionType[key],
        });
      }
      for (const key in workordercompanyType) {
        workordercompanyTypelist.push({
          value: key,
          label: workordercompanyType[key],
        });
      }
      for (const key in workorderstate) {
        workorderStatelist.push({
          value: key,
          label: workorderstate[key],
        });
      }
      for (const key in workordertype) {
        workorderTypelist.push({
          value: key,
          label: workordertype[key],
        });
      }
      this.workorderquestionType = list;
      this.workordercompanyType = workordercompanyTypelist;
      this.workorderState = workorderStatelist;
      this.workorderTypelist = workorderTypelist;
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.orderNo) {
        params.orderNo = this.orderNo;
      }
      if (this.orderAreaId) {
        params.orderAreaId = this.orderAreaId;
      }
      if (this.orderPersonName) {
        params.orderPersonName = this.orderPersonName;
      }
      if (this.createUser) {
        params.createUser = this.createUser;
      }
      if (this.chatContent) {
        params.chatContent = this.chatContent;
      }
      if (this.orderCompanyName) {
        params.orderCompanyName = this.orderCompanyName;
      }
      if (this.orderquestionType) {
        params.orderQuestionType = this.orderquestionType;
      }
      if (this.orderState) {
        params.orderState = this.orderState;
      }
      if (this.orderType) {
        params.orderType = this.orderType;
      }
      if (this.ordercompanyType) {
        params.orderCompanyType = this.ordercompanyType;
      }
      if (this.createTime) {
        params.createTimeStart = this.createTime[0];
        params.createTimeEnd = this.createTime[1];
      }
      this.doFetch({
        url: "/repairOrder/work/order/operating/pageList/",
        params,
        pageNum,
      });
    },
    //编辑
    editModel(contractId) {
      this.$router.push({
        path: "/web/financialSettlement/contractManagementAdd",
        query: {
          contractId,
        },
      });
    },
    //删除
    deleteOrder(orderId) {
      this.doDel({
        url: "/repairOrder/work/order/delete",
        msg: "确定删除工单吗？",
        ps: {
          type: "post",
          data: { orderId },
        },
      });
    },
    //完成
    completeOrder(orderId) {
      this.$post("/repairOrder/work/order/complete", { orderId })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "工单已处理完成",
              type: "success",
            });
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    //新增工单
    addModel() {
      this.$router.push({
        path: "/web/workOrder/workOrderEdit",
      });
    },

    lookOrder(orderId, chatId, orderState) {
      this.$post("/repairOrder/work/order/toView", { orderId })
        .then((ret) => {
          if (ret.status == "0") {
            this.toVoidLoding = true;
           this.tovoidData = ret.data.edoWorkChatTmpBOList.map(el => {
               el.tmpFileKeys.map(els => {
                  els.fileType = els.name.substring(els.name.lastIndexOf(".") + 1);
               })
             })
           
             this.tovoidData = ret.data;
            this.chatId = chatId;
            this.orderId = orderId;
            this.lorderState = orderState;
             setTimeout(() => {
              this.$nextTick(() => {
		//设置滚动条到最底部
		this.$refs.doma.scrollTop = this.$refs.doms.scrollHeight 
        })
            }, 100);
          }
        })
        .catch((err) => {
          return;
        });
    },
    toVoidClose() {
      this.toVoidLoding = false;
      this.chatContents = '';
      this.getData(-1);
    },

    //弹框确定
    toVoidDetermine() {
      if (this.chatContents.trim() == "") {
        this.$message({
          message: "发送消息不能为空",
          type: "error",
        });
        return;
      } else {
        const params = {
          chatContent: this.chatContents,
          chatId: this.chatId,
          orderId: this.orderId,
          chatFileKeys: this.chatFileKeys,
        };
        this.$post("/repairOrder/work/chat/insert", params).then((ret) => {
          console.log(ret);
          this.lookOrder(this.orderId, this.chatId);
          this.chatContents = "";
           this.NameList = [];
          this.fileList = [];
          this.chatFileKeys = []
        });
      }
    },
    uploadChange(file,fileList) {
      this.fileList = fileList
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      //   const isXLSX = extension === "XLSX";
      //   const isxlsx = extension === "xlsx";
      //   const isXLS = extension === "XLS";
      //   const isxls = extension === "xls";
      //   if (!isXLSX && !isxlsx && !isXLS && !isxls) {
      //     this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
      //     return;
      //   }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }

      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.NameList.push({
            name: file.name,
            fileKey: result.data.fileKey,
          });
          this.chatFileKeys.push(result.data.fileKey + "_" + file.name);
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl(index) {
      this.NameList.splice(index, 1);
      this.chatFileKeys.splice(index, 1);
      this.fileList.splice(index, 1);
      this.$forceUpdate();
    },
    jumpDown(index, url) {
      window.open(url);
    },
     jumpLook(index, item) {
        let extension = item.name.substring(item.name.lastIndexOf(".") + 1);
        this.fileType = extension;
      if(this.fileType == 'pdf' || this.fileType == 'PDF') {
         this.previewLoding1 = true;
      this.$nextTick(() => {
            pdf.embed(item.pdfUrl, "#pdf-cert1");
          });
      } else if(this.fileType == 'png' || this.fileType == 'jpg' || this.fileType == 'jpeg'){
         this.previewLoding1 = true;
        this.ImgSrc =item.url
      } else {
         window.open("https://view.officeapps.live.com/op/view.aspx?src="+encodeURIComponent(item.url))
      }
    },
     uploadLodClose() {
       this.previewLoding1 = false;
        this.ImgSrc = ''
    },
    removeSearch() {
      this.orderNo = "";
      this.orderAreaId = "";
      this.orderPersonName = "";
      this.createUser = "";
      this.chatContent = "";
      this.orderCompanyName = "";
      this.orderquestionType = "";
      this.orderState = "";
      this.orderType = "";
      this.ordercompanyType = "";
      this.createTime = "";
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function(route) {
        if (route.query.refresh == true) {
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  margin-top: 15px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
.el-collapse-item__header {
  height: 21px;
  line-height: 21px;
   background:none;
  .el-collapse-item__content {
    margin-bottom: 10px !important;
  }
}
.el-collapse-item__wrap {
  background:none;
}
.remind {
    .el-form-item__content {
        line-height: 1.5rem;
        .colorkey {
            color:tomato
        }
    }
}
.rejectBox {
  display: flex;
  align-items: center;
  span {
    padding-left:.25rem;
    font-size: .9rem;
  }
}
.el_CardRed {
  background-color:#FAEBEB;
  color:#F36464;
  border:1px solid #FAEBEB
}
.el_CardGreen {
  background-color:#EAF7F4;
  color: #5DD9B9;
  border:1px solid #EAF7F4
}
.el_CardBlue {
  background-color:#EAEFFB;
  color:#598EFE;
  border:1px solid #EAEFFB
}
.bgc-redd:hover,
.bgc-redd:focus,
.bgc-redd:disabled,
.bgc-redd:disabled:hover {
  background-color: #e33a1d;
  border-color: #e33a1d;
  color: #fff;
}

.bgc-blued:hover,
.bgc-blued:focus,
.bgc-blued:disabled,
.bgc-blued:disabled:hover {
  background-color: #248bff;
  border-color: #248bff;
  color: #fff;
}
.bgc-green:hover,
.bgc-green:focus,
.bgc-green:disabled,
.bgc-green:disabled:hover {
  background-color: #08c6a2;
  border-color: #08c6a2;
  color: #fff;
}
</style>
<style lang="less">
.dialog-footers {
  .el-textarea__inner {
    resize: none;
    min-height: 100px !important;
  }
}

</style>
